import logo from "./logo.svg";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Flex,
  Text,
  SwitchField
} from "@aws-amplify/ui-react";


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import MaterialButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';

import {post, getUsers} from "./UserService"
import React, {useMemo, useState, useEffect}  from "react";


function App({ signOut }) {

  const [inputValue, setInputValue] = useState()

  let currentDate = new Date();
  let startDate = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - startDate) /
      (24 * 60 * 60 * 1000));
        
  var weekNumber = Math.ceil(days / 7);

  const [open, setOpen] = React.useState(false);
  const [pNR, setpNr] = useState(100)
  const [name, setName] = useState("")


  const [userData, setUserData] = useState([])
  const [shownData, setShownData] = useState([])
  const [isChecked, setIsChecked] = useState(true);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [group, setGroup] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [isNew, setIsNew] = useState(true)

  const selectHandleChange = (event) => {
    setGroup(event.target.value);
  };


  const pNRHandleChange = (event) => {
   setpNr(event.target.value);
  };
  const nameHandleChange = (event) => {
  setName(event.target.value);
  };

  const handleClickOpen = () => {

if(!isNew){
  setName("")
  setpNr(100)
  setGroup('')
 setArrivalDate(new Date())
 setIsNew(true)
} else {
  setName("")
  setpNr(100)
}

    setDialogTitle("Ny bruker")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleSave = () => {
    setOpen(false); 

    let patient_object= {
      userId: pNR,
      groupId: group, 
      firstName: name, 
      arrivalDate: arrivalDate, 
      groupId: group
}

console.log("DAte " + arrivalDate)

post(patient_object)
  }
 
   useEffect(() => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ', 
        }
    };
   fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllUsers', requestOptions)
   .then(response => response.json())
   .then(function(data) {
    setUserData(data.Items)

    setShownData(data.Items)
  },);

}, []); 

const formatDate = (date) => {


var newDate = new Date(date)

let day = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

let format = day + "-" + month + "-" + year

return format;

}

const handleRowClick = (e) => {
 console.log(e.firstName); 

 setDialogTitle("Rediger bruker")
 setOpen(true);
 setName(e.firstName)
 setpNr(e.userId)
 setGroup(e.groupId)
setArrivalDate(new Date(e.arrivalDate))

setIsNew(false)
}



 const filterFutureUsers = () => {

   setShownData(userData.filter(value => new Date(value.arrivalDate).getTime() > new Date(currentDate).getTime()))
 }

 const filterCurrentUsers = () => {

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

let firstSort = userData.filter(value => new Date(value.arrivalDate).getTime() <= new Date(currentDate).getTime())

setShownData(firstSort.filter(value => new Date(value.arrivalDate).addDays(20).getTime() > new Date(currentDate).getTime()))
 }

const filterPreviousUsers = () => {

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  setShownData(userData.filter(value => new Date(value.arrivalDate).addDays(20).getTime() < new Date(currentDate).getTime()))
}

 const allUsers = () => {
  setShownData(userData)
}

    return (
        <View className="App">
          <Flex  direction="column">
  
        <Container sx={{ mb: 10, mt: 2 }} direction="column">
        <Heading   level={1}>Brukeroversikt</Heading>
          <MaterialButton  sx={{ width: '20%', mx: 3, top: '2%', right: '0%', position: 'absolute', }} onClick={signOut}>Logg ut</MaterialButton>
        </Container>
        <Box   
       sx={{ width: '20%', mx: 3, top: '18%', right: '2%', position: 'absolute', }}>
        <MaterialButton  
      variant="contained" onClick={handleClickOpen}>
        Opprett ny bruker
      </MaterialButton>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Legg inn nødvendige pasientopplysninger. Alle felt er obligatoriske.
          </DialogContentText>
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="patientNumber"
            label="Pasientnummer"
            fullWidth
            type="number"
            variant="standard"
            value={pNR}
            onChange={pNRHandleChange}
            required
          />
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="patientName"
            label="Fornavn"
            fullWidth
            variant="standard"
            value={name}
            onChange={nameHandleChange}
            required
          />
          
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">  
  <InputLabel id="demo-simple-select-label">Gruppe</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={group}
    label="Gruppe"
    onChange={selectHandleChange}
  >
    <MenuItem value={"2C"}>2C</MenuItem>
    <MenuItem value={"2D"}>2D</MenuItem>
    <MenuItem value={"2E"}>2E</MenuItem>
    <MenuItem value={"1B"}>1B</MenuItem>
    <MenuItem value={"TEST"}>TEST</MenuItem>
  </Select>
</FormControl>
<Typography sx={{ mt: 2 }} variant="subtitle2">Ankomstdato</Typography>
<DatePicker selected={arrivalDate} onChange={(date) => setArrivalDate(date)} />
<Typography
sx={{ mt: 4 }} variant="subtitle2">Sesong</Typography>
<FormControlLabel control={<Switch defaultChecked />} label="Vinter" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleSave}>Lagre</Button>
        </DialogActions>
      </Dialog>

           <Container>
             
              <MaterialButton onClick={allUsers}>Alle brukere</MaterialButton>
              <MaterialButton onClick={filterPreviousUsers} >Tidligere</MaterialButton>
              <MaterialButton onClick={filterCurrentUsers}> Nåværende</MaterialButton>
              <MaterialButton onClick={filterFutureUsers}>Kommende</MaterialButton>
              <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Pasientnummer</TableCell>
            <TableCell  align="center"> Fornavn</TableCell>
            <TableCell align="right">Dato</TableCell>
            <TableCell align="right">Gruppenummer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shownData.map((user) => (
            <TableRow
              onClick={() => handleRowClick(user)}
              key={user.userId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {user.userId}
              </TableCell>
              <TableCell align="center">{user.firstName}</TableCell>
              <TableCell align="right">{formatDate(user.arrivalDate)}</TableCell>
              <TableCell align="right">{user.groupId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
          </Flex>
        
        </View>
      );
}

export default withAuthenticator(App);