import React from 'react';
import { node } from 'prop-types';


export const post = async(user) =>{


    const postUser = JSON.stringify(user)

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'k3z6bi0JJj73fPtTkXcTkI4QVJf4mFf7UbalzTp5'
        },
        body: postUser
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/userHandlerAdmin', requestOptions)
        .then(response => console.log(response.status))
}

export const getUsers = async() =>{
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia'
        },
      
    };

   const response = await fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllUsers', requestOptions);
   const json = await response.json()

    const patients = json.Items

    console.log(patients)

    return patients;


   // console.log("PASIENTNR: " + JSON.stringify(json)+ " JSON " + json.data)
    //if(JSON.stringify(json).length <= 150){
  /*   return false 
    } else {
        return true
    }  */
}
